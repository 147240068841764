<template>
    <div>
        <CModalExtended
            size="lg"
            color="dark"
            :closeOnBackdrop="false"
            :show.sync="$store.state.unidadmedida.modalUnitMeasure"
            :title="titleModalUnitMeasure"
            class="modal-extended"
        >
            <CRow>
                <CCol sm="11" lg="12" xl="12">
                    <div class="form-group form-row" rol="group">
                        <label class="required col-form-label col-sm-11 col-lg-4 col-xl-4 text-right" 
                                for="labelPuerto">{{$t('label.measurementUnitType')}}</label>
                        <div class="col-sm-11 col-lg-7 col-xl-7 input-group">
                            <select                                                           
                                @blur="$v.formUnidadMedida.IdTpUnidadMedida.$touch()"
                                v-model="$v.formUnidadMedida.IdTpUnidadMedida.$model" 
                                :class="InvalidSelect" id="inputGroupSelect01"
                            >
                                <option value="">{{$t('label.select')}}</option>
                                <option v-for="items in filteredTipeItems" :key="items.Nro" :value="items.TpUnitMeasureId">{{ items.TpUnitMeasureName }}</option>
                            </select>
                           <div class="input-group-append" style="height:35px;">
                               <CButton
                                    @click="showCollapseTypeUnitMeasure()"
                                    size="sm"
                                    :color="checkColorCollapse"
                                    v-c-tooltip="{
                                    content: $t('label.add')+$t('label.type'),
                                    placement: 'top-end',
                                    }"
                                >
                                    <CIcon :name="checkIconCollapse" /> 
                                </CButton>
                                <!--button 
                                    @click="showCollapseTypeUnitMeasure()" 
                                    class="btn btn-add" 
                                    
                                    type="button"
                                    v-c-tooltip="{
                                        content: $t('label.add')+$t('label.type'),
                                        placement: 'top-end'
                                    }"
                                >
                                    +
                                </button>

                                <CIcon :name="checkIconCollapse" /-->
                            </div>
                            <div class="invalid-feedback col-sm-11 col-lg-11 col-xl-11 p-0" v-if="$v.formUnidadMedida.IdTpUnidadMedida.$error">
                                <div class='text-danger' v-if="!$v.formUnidadMedida.IdTpUnidadMedida.requiredSelect">{{$t('validation.required')}}</div>
                            </div>
                        </div>
                        
                    </div>
                    <collapseTypeUnitMeasure />
                </CCol>
                <CCol sm="11" lg="12" xl="12">
                    <CInput
                    :label="$t('label.measureUnit')"
                    addLabelClasses="required text-right"
                    v-uppercase
                    :horizontal="{ label: 'col-sm-11 col-lg-4 col-xl-4', input:'col-sm-11 col-lg-7 col-xl-7'}"
                    :placeholder="$t('label.measurementUnitName')"
                    @blur="$v.formUnidadMedida.NbUnidadMedida.$touch()"
                    v-model="$v.formUnidadMedida.NbUnidadMedida.$model"
                    required 
                    :is-valid="hasError($v.formUnidadMedida.NbUnidadMedida)" 
                    :invalid-feedback="errorMessage($v.formUnidadMedida.NbUnidadMedida)"
                    >
                    </CInput>
                </CCol>
                <CCol sm="11" lg="12" xl="12">
                    <CInput
                    :label="$t('label.acronym')"
                    addLabelClasses="required text-right"
                    :horizontal="{ label: 'col-sm-11 col-lg-4 col-xl-4', input:'col-sm-11 col-lg-7 col-xl-7'}"
                    v-uppercase
                    :placeholder="$t('label.measurementUnitAcronym')"
                    @blur="$v.formUnidadMedida.SiglasUnidadMedida.$touch()"
                    v-model="$v.formUnidadMedida.SiglasUnidadMedida.$model"
                    required 
                    :is-valid="hasError($v.formUnidadMedida.SiglasUnidadMedida)" 
                    :invalid-feedback="errorMessage($v.formUnidadMedida.SiglasUnidadMedida)"
                    >
                    </CInput>
                </CCol>
                <CCol sm="11" lg="12" xl="12">
                    <CSelect
                        :label="$t('label.status')"
                        addLabelClasses="required text-right"
                        :horizontal="{ label: 'col-sm-11 col-lg-4 col-xl-4', input:'col-sm-11 col-lg-7 col-xl-7'}"                
                        v-if="!idIndentification"
                        v-model="formUnidadMedida.FgActUnidadMedida"
                        :value.sync="formUnidadMedida.FgActUnidadMedida"
                        :options="selectOptions"
                        :is-valid="formUnidadMedida.FgActUnidadMedida" 
                    />
                </CCol>
            </CRow>
            <CElementCover 
                 :opacity="0.8"
                v-if="loading"
            >
                <label class="d-inline">{{$t('label.load')}}... </label>
                <CSpinner size="sm" />
            </CElementCover>
            <div slot="footer">
                <CButton
                    color="add"
                    class="m-2"
                    @click="registerData"
                    :disabled="apiStateLoading"
                >                 
                    <div v-if="!apiStateLoading && !loading">
                        <CIcon name="checkAlt"/>&nbsp;
                       {{$t('button.accept')}}
                    </div>
                </CButton>
                <CButton
                    color="wipe"
                    @click="closeModal"
                    
                    :disabled="apiStateLoading || loading "
                >
                <CIcon name="x"/>&nbsp;
                     {{$t('button.cancel')}}
                </CButton>
            </div>
        </CModalExtended>
    </div>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState,mapMutations } from 'vuex';
    import { FormUnidadMedida } from '@/_validations/unidadmedida/UnidadValidations';
    import UpperCase  from '@/_validations/uppercase-directive';
    import collapseTypeUnitMeasure from './collapse-type-unit-measure.vue';
    import general from '@/_mixins/general';
    import modalMixin from '@/_mixins/modal';

    //data
    function data() {
        return {
            formUnidadMedida:{
                IdUnidadMedida:0,
                NbUnidadMedida:'',
                SiglasUnidadMedida:'',
                FgActUnidadMedida:true,
                IdTpUnidadMedida:''
            },
            originalAct:true,
            loading:false
        }
    }

    //methods
    function cambio(e){
        this.formUnidadMedida.FgActUnidadMedida = !this.FgActUnidadMedida.FgActUnidadMedida;
    }
    function closeModal(){
        if(this.apiStateLoading || this.loading)
            return
        this.$store.commit('unidadmedida/mutationModalUnitMeasure', false);
    }
    function registerData(){
        try { 
            this.$v.formUnidadMedida.$touch();
            if (this.$v.formUnidadMedida.$pending || this.$v.formUnidadMedida.$error)
                throw this.$t('label.errorsPleaseCheck');

            if (this.$v.formUnidadMedida.$pending || this.$v.formUnidadMedida.$error) return;

            let { IdUnidadMedida,NbUnidadMedida,SiglasUnidadMedida,FgActUnidadMedida,IdTpUnidadMedida } = this.formUnidadMedida;
            const { dispatch } = this.$store;
            //var source = 'https://www.google.es/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png';
            
            if(this.originalAct !== '' && IdUnidadMedida !== 0){
                if(this.originalAct !== FgActUnidadMedida){              
                        this.$swal
                            .fire(this.alertProperties({
                                text: `${this.$t('label.changeStatusQuestion')+' '} ${this.formUnidadMedida.NbUnidadMedida}?`,
                            }))     
                        .then((result) => {
                        if (result.isConfirmed) {
                            dispatch('unidadmedida/postregisterUnidad', { IdUnidadMedida,IdTpUnidadMedida,NbUnidadMedida,SiglasUnidadMedida,FgActUnidadMedida });
                        }
                    })
                }else{
                    dispatch('unidadmedida/postregisterUnidad', { IdUnidadMedida,IdTpUnidadMedida,NbUnidadMedida,SiglasUnidadMedida,FgActUnidadMedida });
                }
            }else{
                dispatch('unidadmedida/postregisterUnidad', { IdUnidadMedida,IdTpUnidadMedida,NbUnidadMedida,SiglasUnidadMedida,FgActUnidadMedida });
            }
        } catch (e) {
            this.notifyError({ text: e });
        }
    }

    function showCollapseTypeUnitMeasure(){
        this.$store.state.unidadmedida.unitMeasureTypeId = 0;
        //this.$store.state.unidadmedida.collapseTypeUnitMeasure = true;
        this.$store.state.unidadmedida.collapseTypeUnitMeasure = !this.$store.state.unidadmedida.collapseTypeUnitMeasure;
    }

    function checkIconCollapse(){
        return this.$store.state.unidadmedida.collapseTypeUnitMeasure ? "cil-minus" : "cil-plus" ;
    }

    function checkColorCollapse(){
        return this.$store.state.unidadmedida.collapseTypeUnitMeasure ? "wipe" : "add" ;

       /* if(this.$store.state.unidadmedida.collapseTableTipoUnidad){
            return 'danger';
        }else{
            return 'primary'
        }*/
    }

    //computed
    function InvalidSelect(){
        return this.$v.formUnidadMedida.IdTpUnidadMedida.$error ? 'custom-select is-invalid':  'custom-select';
    }
    function idIndentification(){
        return this.formUnidadMedida.IdUnidadMedida === 0;
    }
    function apiStateLoading() {
        return this.apiState === ENUM.LOADING;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING || this.apiStateForm === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    function computedItems () {
        return this.$store.getters["unidadmedida/myDatatableUnitMeasureType"];
    }
    function filteredTipeItems () {
        return this.computedItems.filter(u => u.FgActTpUnitMeasure);
    }

    function selectOptions() {
    return[
      {
        value: true,
        label: this.$t('label.ACTIVO'),
      },
      {
        value: false,
        label: this.$t('label.INACTIVO'),
      },
    ]
    }

    //watch
    function modalUnitMeasure(newQuestion,Oldquestion){
        if(newQuestion === false){
            this.formUnidadMedida.IdUnidadMedida=0;
            this.formUnidadMedida.NbUnidadMedida='';
            this.formUnidadMedida.SiglasUnidadMedida='';
            this.formUnidadMedida.FgActUnidadMedida=true;
            this.formUnidadMedida.IdTpUnidadMedida='';
            this.$store.state.unidadmedida.collapseTypeUnitMeasure = false;
            this.originalAct = true;
            this.$nextTick(() => { this.$v.$reset() })
            this.$store.commit('unidadmedida/setUnitMeasureId', 0);
        }else{
            this.$store.dispatch('unidadmedida/getTipoUnidadlist');
        }
    }
    function idState(newQuestion,Oldquestion){
        if(this.modalUnitMeasure){
            if(newQuestion !== 0){
                this.$store.state.unidadmedida.apiStateForm = ENUM.LOADING;
                this.$http.ejecutar('GET', 'UnitMeasure-by-id',{ UnitMeasureId: newQuestion }, '' ).then(response => {
                    if(response.status === 200){
                        try {
                            const UnidadInformation = response.data.data;
                            if(UnidadInformation.length !== 0){
                                this.formUnidadMedida.IdUnidadMedida = UnidadInformation[0].UnitMeasureId;
                                this.formUnidadMedida.NbUnidadMedida = UnidadInformation[0].UnitMeasureName;
                                this.formUnidadMedida.SiglasUnidadMedida = UnidadInformation[0].UnitMeasureAbbrev;
                                this.formUnidadMedida.IdTpUnidadMedida = UnidadInformation[0].TpUnitMeasureId;
                                const Index = this.computedItems.map(function(e) {
                                    if(e.FgActTpUnitMeasure === true){
                                        return e.TpUnitMeasureId; 
                                    }
                                }).indexOf(UnidadInformation[0].TpUnitMeasureId);
                                if(Index === -1){
                                    this.formUnidadMedida.IdTpUnidadMedida = "";
                                }
                                this.originalAct = UnidadInformation[0].FgActUnitMeasure;
                                this.formUnidadMedida.FgActUnidadMedida = UnidadInformation[0].FgActUnitMeasure;
                                this.$v.formUnidadMedida.$touch();
                                this.$store.state.unidadmedida.apiStateForm = ENUM.INIT;
                            }else{
                                this.$store.state.unidadmedida.apiStateForm = ENUM.ERROR;
                                this.$store.commit('unidadmedida/mutationModalUnitMeasure', false);
                            }
                        } catch (error) {
                            this.$store.state.unidadmedida.apiStateForm = ENUM.ERROR;
                            this.$store.commit('unidadmedida/messageMutation', error);
                            this.$store.commit('unidadmedida/mutationModalUnitMeasure', false);
                        }
                    }else{
                        this.$store.state.unidadmedida.apiStateForm = ENUM.ERROR;
                        this.$store.commit('unidadmedida/mutationModalUnitMeasure', false);
                    }
                }).catch(err => {
                    this.$store.state.unidadmedida.apiStateForm = ENUM.ERROR;
                    this.$store.commit('unidadmedida/messageMutation', err);
                    this.$store.commit('unidadmedida/mutationModalUnitMeasure', false);
                });
            }
        }
    }

    export default {
        name: 'modal-unit-measure',
        data,
        mixins:[general, modalMixin],
        props: {
            titleModalUnitMeasure:{ 
                type: String,
                default: false,
            }
        },
        components: {
            collapseTypeUnitMeasure
        },
        directives: UpperCase,
        validations(){ return FormUnidadMedida() },
        computed: { 
            idIndentification,
            apiStateLoading,
            apiStateFormLoading,
            computedItems,
            InvalidSelect,
            filteredTipeItems,
            selectOptions,
            checkIconCollapse,
            checkColorCollapse,
            ...mapState({
                idState: state=> state.unidadmedida.id,
                modalUnitMeasure: state=> state.unidadmedida.modalUnitMeasure,
                apiState: state => state.unidadmedida.apiState,
                apiStateForm: state => state.unidadmedida.apiStateForm
            })
        },
        methods:{
            cambio,
            showCollapseTypeUnitMeasure,
            closeModal,
            registerData,
            
        },
        watch:{
            modalUnitMeasure,
            idState
        }
    }
</script>